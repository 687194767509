.file-upload-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.file-upload-label {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.file-upload-label .upload-icon {
  margin-right: 8px;
}

.file-upload-text {
  white-space: nowrap;
}

.file-name-display {
  margin-top: 8px;
  font-size: 12px;
  color: #586e75;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Upload animation */
@keyframes upload-spin {
  100% {
    transform: rotate(360deg);
  }
}

.upload-icon.uploading {
  animation: upload-spin 1s linear infinite;
}

/* Success animation */
@keyframes success-pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.upload-icon.success {
  animation: success-pulse 0.5s ease-in-out;
}

/* Error animation */
@keyframes error-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
}

.upload-icon.error {
  animation: error-shake 0.5s ease-in-out;
}

/* Light theme styles */
body.light .file-upload-label {
  background-color: #fdf6e3;
  color: #657b83;
  border: 1px solid #93a1a1;
}

body.light .file-upload-label:hover {
  background-color: #eee8d5;
  border-color: #859900;
}

body.light .file-upload-label.uploading {
  background-color: #268bd2;
  color: #fdf6e3;
  border-color: #268bd2;
}

body.light .file-upload-label.success {
  background-color: #859900;
  color: #fdf6e3;
  border-color: #859900;
}

body.light .file-upload-label.error {
  background-color: #dc322f;
  color: #fdf6e3;
  border-color: #dc322f;
}

/* Dark theme styles */
body.dark .file-upload-label {
  background-color: #002b36;
  color: #93a1a1;
  border: 1px solid #586e75;
}

body.dark .file-upload-label:hover {
  background-color: #073642;
  border-color: #268bd2;
}

body.dark .file-upload-label.uploading {
  background-color: #268bd2;
  color: #fdf6e3;
  border-color: #268bd2;
}

body.dark .file-upload-label.success {
  background-color: #859900;
  color: #fdf6e3;
  border-color: #859900;
}

body.dark .file-upload-label.error {
  background-color: #dc322f;
  color: #fdf6e3;
  border-color: #dc322f;
}
