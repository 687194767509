@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

:root {
  --bg-dark: #002b36;
  --bg-light: #fdf6e3;
  --text-dark: #839496;
  --text-light: #657b83;
  --primary-dark: #268bd2;
  --primary-light: #2aa198;
  --secondary-dark: #073642;
  --secondary-light: #eee8d5;
}

.light {
  --disabled-button-bg: #93a1a1;
  --disabled-button-text: #eee8d5;
}

.dark {
  --disabled-button-bg: #586e75;
  --disabled-button-text: #073642;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 900px;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

#editor-container {
  margin-bottom: 1rem;
  border: 1px solid var(--primary-dark);
  border-radius: 3px;
  overflow: hidden;
}

.CodeMirror {
  height: auto;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  font-size: 14px;
  line-height: 1.5;
}

button {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background-color: var(--primary-dark);
  color: var(--text-dark);
  transition:
    background-color 0.3s,
    transform 0.1s;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
}

button:hover {
  background-color: var(--primary-light);
  color: var(--text-light);
}

button:active {
  transform: translateY(1px);
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.error {
  color: #ff5555;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

body.dark {
  background-color: var(--bg-dark);
  color: var(--text-dark);
}

body.light {
  background-color: var(--bg-light);
  color: var(--text-light);
}

.container {
  padding: 2rem;
  max-width: 900px;
  margin: 0 auto;
}

.theme-toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: inherit;
  z-index: 1000;
}

.light #themeToggle {
  color: var(--text-light);
}

@media (max-width: 768px) {
  body {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }
}
#status {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 3px;
  background-color: var(--secondary-dark);
  color: var(--text-dark);
  transition:
    background-color 0.3s,
    color 0.3s;
}

body.light #status {
  background-color: var(--secondary-light);
  color: var(--text-light);
}

#output {
  width: 100%;
  border: 1px solid var(--primary-dark);
  border-radius: 3px;
  padding: 1rem;
  margin-bottom: 1rem;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
    monospace;
  font-size: 0.9rem;
  background-color: var(--secondary-dark);
  color: var(--text-dark);
  height: auto;
  min-height: 150px;
  overflow-y: auto;
  white-space: pre-wrap;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
}

body.light #output {
  background-color: var(--secondary-light);
  color: var(--text-light);
  border-color: var(--primary-light);
}

.editor-container {
  max-width: 900px;
  margin: 0 auto;
  overflow-x: hidden;
  margin-bottom: 20px; /* Add space below the editor */
}

.CodeMirror {
  height: auto;
  max-height: 500px;
}

.CodeMirror-scroll {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.CodeMirror-hscrollbar {
  display: none !important;
}

.CodeMirror-sizer {
  margin-bottom: 0 !important;
}

/* Signature styles */
.signature {
  position: fixed;
  bottom: 1rem;
  right: 1rem; /* Changed from left: 50% to right: 1rem */
  transform: none; /* Removed translateX(-50%) */
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  --shadow-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px var(--shadow-color);
  text-align: right;
  z-index: 1000;
}

.signature a {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.signature .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.left-buttons {
  display: flex;
  align-items: center;
}

.run-button,
.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.1s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.run-button {
  color: #ffffff;
  background-color: #4caf50;
}

.clear-button {
  background-color: #cb4b16;
  color: #ffffff;
}

.run-button:hover,
.clear-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
  color: #ffffff; /* Ensure text remains white on hover */
}

.run-button:hover {
  background-color: #45a049;
}

.clear-button:hover {
  background-color: #cb4b16;
}

.run-button:active {
  background-color: #3e8e41;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transform: translateY(1px);
  color: #ffffff; /* Ensure text remains white when active */
}

.run-button:disabled,
.clear-button:disabled {
  background-color: var(--disabled-button-bg, #93a1a1);
  color: var(--disabled-button-text, #eee8d5);
  cursor: not-allowed;
  box-shadow: none;
  transform: none;
}

.run-button svg,
.clear-button svg {
  width: 20px;
  height: 20px;
}

.run-button svg {
  margin-right: 8px;
}

body.light .run-button {
  background-color: #2196f3;
}

body.light .run-button:hover {
  background-color: #1e88e5;
  color: #ffffff; /* Ensure text remains white on hover in light mode */
}

body.light .run-button:active {
  background-color: #1976d2;
  color: #ffffff; /* Ensure text remains white when active in light mode */
}

body.light .clear-button {
  background-color: #ff5722; /* A warm orange color for the clear action */
}

body.light .clear-button:hover {
  background-color: #f4511e;
  color: #ffffff; /* Ensure text remains white on hover in light mode */
}

body.light .clear-button:active {
  background-color: #e64a19;
  color: #ffffff; /* Ensure text remains white when active in light mode */
}

/* Template Selector Styles */
.template-selector {
  position: relative;
  margin-bottom: 15px;
  width: 400px;
}

.template-selector select {
  appearance: none;
  width: 100%;
  padding: 10px 35px 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.template-selector svg {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.3s ease;
}

/* Light theme styles */
body.light .template-selector select {
  background-color: #eee8d5;
  color: #657b83;
  border: 1px solid #93a1a1;
}

body.light .template-selector select:hover,
body.light .template-selector select:focus {
  background-color: #fdf6e3;
  border-color: #859900;
}

body.light .template-selector svg {
  color: #657b83;
}

body.light .template-selector:hover svg {
  color: #859900;
}

/* Dark theme styles */
body.dark .template-selector select {
  background-color: #073642;
  color: #93a1a1;
  border: 1px solid #586e75;
}

body.dark .template-selector select:hover,
body.dark .template-selector select:focus {
  background-color: #002b36;
  border-color: #268bd2;
}

body.dark .template-selector svg {
  color: #93a1a1;
}

body.dark .template-selector:hover svg {
  color: #268bd2;
}

/* Ensure the select element text doesn't overflow */
.template-selector select {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* Style for when the select is open (optional, works in some browsers) */
.template-selector select:active,
.template-selector select:focus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* Modern Borderless Table Styles */
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1.5rem;
  font-size: 0.95rem;
}

thead {
  background-color: transparent;
}

th {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 16px;
  text-align: left;
}

td {
  padding: 16px;
  vertical-align: top;
}

tr {
  transition: background-color 0.2s ease;
}

/* Light theme styles */
body.light table {
  color: #657b83;
}

body.light th {
  color: #586e75;
}

body.light tr:nth-child(even) {
  background-color: rgba(238, 232, 213, 0.3); /* #eee8d5 with low opacity */
}

body.light tr:hover {
  background-color: rgba(238, 232, 213, 0.5); /* #eee8d5 with higher opacity */
}

/* Dark theme styles */
body.dark table {
  color: #93a1a1;
}

body.dark th {
  color: #eee8d5;
}

body.dark tr:nth-child(even) {
  background-color: rgba(7, 54, 66, 0.3); /* #073642 with low opacity */
}

body.dark tr:hover {
  background-color: rgba(7, 54, 66, 0.5); /* #073642 with higher opacity */
}

/* Responsive table */
@media screen and (max-width: 600px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
  }

  td {
    position: relative;
    padding-left: 50%;
    text-align: right;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  td::before {
    content: attr(data-label);
    position: absolute;
    left: 16px;
    width: 45%;
    padding-right: 10px;
    text-align: left;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.85em;
  }

  /* Light theme responsive */
  body.light tr {
    background-color: rgba(238, 232, 213, 0.2);
  }

  body.light tr:nth-child(even) {
    background-color: rgba(238, 232, 213, 0.4);
  }

  /* Dark theme responsive */
  body.dark tr {
    background-color: rgba(7, 54, 66, 0.2);
  }

  body.dark tr:nth-child(even) {
    background-color: rgba(7, 54, 66, 0.4);
  }
}

/* Optional: Add a subtle box shadow for depth in larger screens */
@media screen and (min-width: 601px) {
  table {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }

  /* Light theme shadow */
  body.light table {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  /* Dark theme shadow */
  body.dark table {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
}

/* CodeMirror custom scrollbar */
.CodeMirror-simplescroll-vertical,
.CodeMirror-simplescroll-horizontal {
  width: 10px;
  background-color: transparent;
}

.CodeMirror-simplescroll-vertical div,
.CodeMirror-simplescroll-horizontal div {
  background-color: rgba(147, 161, 161, 0.5); /* Solarized base1 with opacity */
  border-radius: 5px;
  border: none;
}

/* Light theme scrollbar */
body.light .CodeMirror-simplescroll-vertical div,
body.light .CodeMirror-simplescroll-horizontal div {
  background-color: rgba(88, 110, 117, 0.5); /* Solarized base01 with opacity */
}

/* Dark theme scrollbar */
body.dark .CodeMirror-simplescroll-vertical div,
body.dark .CodeMirror-simplescroll-horizontal div {
  background-color: rgba(147, 161, 161, 0.5); /* Solarized base1 with opacity */
}

/* Hover styles */
.CodeMirror-simplescroll-vertical div:hover,
.CodeMirror-simplescroll-horizontal div:hover {
  background-color: rgba(147, 161, 161, 0.7); /* Slightly darker on hover */
}

body.light .CodeMirror-simplescroll-vertical div:hover,
body.light .CodeMirror-simplescroll-horizontal div:hover {
  background-color: rgba(88, 110, 117, 0.7);
}

body.dark .CodeMirror-simplescroll-vertical div:hover,
body.dark .CodeMirror-simplescroll-horizontal div:hover {
  background-color: rgba(147, 161, 161, 0.7);
}

/* Hide default scrollbars */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.CodeMirror-vscrollbar::-webkit-scrollbar,
.CodeMirror-hscrollbar::-webkit-scrollbar {
  display: none;
}

.CodeMirror-selected {
  background-color: rgba(147, 161, 161, 0.2) !important;
}

body.light .CodeMirror-selected {
  background-color: rgba(88, 110, 117, 0.2) !important;
}

.CodeMirror-cursor {
  border-left: 2px solid #268bd2;
  transition: border-left-color 0.3s;
}

.CodeMirror-focused .CodeMirror-cursor {
  border-left-color: #cb4b16;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.CodeMirror-cursorblink {
  animation: blink 1s infinite;
}

.CodeMirror-gutters {
  border-right: 1px solid rgba(147, 161, 161, 0.3);
  background-color: transparent;
}

.CodeMirror-linenumber {
  color: rgba(147, 161, 161, 0.6);
  padding: 0 5px;
}

body.light .CodeMirror-gutters {
  border-right-color: rgba(88, 110, 117, 0.3);
}

body.light .CodeMirror-linenumber {
  color: rgba(88, 110, 117, 0.6);
}

.CodeMirror-matchingbracket {
  color: #859900 !important;
  text-decoration: underline;
}

.CodeMirror-activeline-background {
  background-color: rgba(255, 255, 255, 0.1);
}

body.light .CodeMirror-activeline-background {
  background-color: rgba(0, 0, 0, 0.05);
}
