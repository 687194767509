/* InteractiveDataframe.css */

.ag-theme-alpine,
.ag-theme-alpine-dark {
  --ag-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --ag-font-size: 14px;
  --ag-border-radius: 4px;
}

.ag-theme-alpine {
  --ag-background-color: #fdf6e3;
  --ag-foreground-color: #657b83;
  --ag-header-background-color: #eee8d5;
  --ag-odd-row-background-color: #f7f2e5;
  --ag-header-foreground-color: #586e75;
  --ag-border-color: #eee8d5;
}

.ag-theme-alpine-dark {
  --ag-background-color: #002b36;
  --ag-foreground-color: #93a1a1;
  --ag-header-background-color: #073642;
  --ag-odd-row-background-color: #00232c;
  --ag-header-foreground-color: #93a1a1;
  --ag-border-color: #073642;
}

.ag-theme-alpine .ag-header,
.ag-theme-alpine-dark .ag-header {
  font-weight: bold;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine-dark .ag-cell {
  padding: 8px;
}

.ag-theme-alpine .ag-row-hover,
.ag-theme-alpine-dark .ag-row-hover {
  background-color: var(--ag-selected-row-background-color);
}

.interactive-dataframe-container {
  margin-top: 20px;
  border: 1px solid var(--ag-border-color);
  border-radius: var(--ag-border-radius);
  overflow: hidden;
}

.interactive-dataframe-container h2 {
  padding: 10px;
  margin: 0;
  background-color: var(--ag-header-background-color);
  color: var(--ag-header-foreground-color);
  font-size: 18px;
  font-weight: bold;
}

.interactive-dataframe-grid {
  height: 400px;
  width: 100%;
}

/* Custom scrollbar styles */
.ag-theme-alpine .ag-body-vertical-scroll-viewport::-webkit-scrollbar,
.ag-theme-alpine-dark .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
  width: 8px;
}

.ag-theme-alpine .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track,
.ag-theme-alpine-dark
  .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track {
  background: var(--ag-background-color);
}

.ag-theme-alpine .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb,
.ag-theme-alpine-dark
  .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
  background-color: var(--ag-border-color);
  border-radius: 4px;
}

.ag-theme-alpine
  .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:hover,
.ag-theme-alpine-dark
  .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb:hover {
  background-color: var(--ag-foreground-color);
}
